<template>
	<v-container
		id="CasinoGroup Component"
		fluid
		tag="section">
		<v-row dense>
			<v-col>
				<v-card class="v-card--material true pa-3">
					<CardHeading
						:loading="loading"
						:loadingTitle="`Loading studio groups...`"
						:color="'secondary'"
						title="Studios"></CardHeading>
					<v-row class="mb-2 align-center">
						<v-col
							cols="9"
							md="4"
							class="pa-0 pl-2">
							<v-autocomplete
								class="ml-2 mr-2 mr-md-3"
								:loading="loading"
								eager
								label="Groups"
								v-model="selectedItem"
								:key="`${list.length}`"
								item-text="name"
								@change="rowClickHandle"
								:disabled="loading"
								:items="list"></v-autocomplete>
						</v-col>
						<v-col
							cols="3"
							md="4"
							class="px-0 pt-0 pb-2 pb-md-4">
							<ConfirmDialog2
								v-if="list.length > 0"
								persistent
								:confirmBtnText="'Delete'"
								:confirmBtnColor="'error'"
								:cancelBtnColor="'kajot-text'"
								:cancelBtnText="'Back'"
								:shouldShow="true"
								@confirm="deleteItem">
								<template v-slot:body>
									This studio group will be deleted.
									<br />
									Do you wish to continue ?
								</template>
								<template v-slot:default="{ showConfirm }">
									<v-btn
										v-if="
											_usrFlagsSome({
												key: 'management.groups.detail',
												val: permissions.DELETE,
											})
										"
										fab
										:small="!isMobile"
										:x-small="isMobile"
										class="error mr-1 mr-md-3"
										@click="showConfirm">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
							</ConfirmDialog2>
							<v-btn
								v-if="
									_usrFlagsSome({
										key: 'management.groups.detail',
										val: permissions.CREATE,
									})
								"
								fab
								:small="!isMobile"
								:x-small="isMobile"
								class="primary"
								@click="createDialog = true">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-col>
						<v-col
							cols="12"
							md="4"
							v-if="
								_usrFlagsSome({
									key: 'management.groups.detail',
									val: permissions.EDIT,
								})
							">
							<EditComponent
								:fullArray="studios"
								:selectedArray="groupStudios"
								:loading="loading"
								noDialog
								noModel
								:maxChips="3"
								ref="editComponent"
								valueKey="name"
								labelKey="name"
								:width="200"
								:key="`${studios.length}`"
								title="Edit Studio List"
								placeholderText="Studios"
								color="primary"
								v-on:submitEditComponent="editGroup">
								<template #appendItem="{ item }">
									<v-chip>ID: {{ item.id }}</v-chip>
								</template>
							</EditComponent>
						</v-col>
					</v-row>
					<v-skeleton-loader
						v-if="loading"
						type="image@2"></v-skeleton-loader>
					<div
						v-else
						class="d-flex justify-center">
						<v-row
							dense
							class="d-flex">
							<v-col
								:key="item.name"
								class="pb-2"
								v-for="item in groupStudios"
								cols="12"
								md="6"
								lg="4"
								xl="3">
								<v-card class="elevation-1 ma-0">
									<v-card-title
										class="secondary d-flex align-center justify-space-between py-2 body-1">
										{{ item.name.toUpperCase() }}
										<v-chip>ID: {{ item.id }}</v-chip>
									</v-card-title>
								</v-card>
							</v-col>
						</v-row>
					</div>
					<v-card-actions
						v-if="
							_usrFlagsSome({
								key: 'management.groups.detail',
								val: permissions.EDIT,
							})
						"
						class="stickyButtons">
						<v-spacer></v-spacer>

						<v-btn
							@click="$refs.editComponent.reset()"
							:disabled="disableSubmit"
							text>
							<v-icon
								fab
								small
								class="primary--text mr-1">
								mdi-restore
							</v-icon>
							Reset
						</v-btn>
						<v-btn
							@click="$refs.editComponent.submit()"
							:disabled="disableSubmit"
							text>
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-dialog
				width="40%"
				class="col-6 ml-auto mr-auto"
				v-model="createDialog">
				<v-card>
					<v-card-title :class="'headline primary py-3 mb-3'">
						Create Permission Group
					</v-card-title>
					<v-card-text>
						<v-card-text>
							<v-form
								ref="form"
								v-model="validEdit">
								<v-text-field
									autofocus
									:rules="[
										rules.required,
										rules.minMaxLength(1, 40),
										noSameName,
									]"
									v-model="textField"
									label="Name"></v-text-field>
								<EditComponent
									:fullArray="studios"
									:selectedArray="newStudios"
									noDialog
									noModel
									ref="createComponent"
									:loading="false"
									valueKey="name"
									labelKey="name"
									:width="500"
									title="Edit Studio List"
									placeholderText="Studios"
									color="primary"
									v-on:submitEditComponent="createGroup">
									<template #appendItem="{ item }">
										<v-chip>ID: {{ item.id }}</v-chip>
									</template>
									<template #actions-wrapper="{ item }">
										<v-chip>ID: {{ item.id }}</v-chip>
									</template>
								</EditComponent>
							</v-form>
						</v-card-text>
					</v-card-text>
					<v-card-actions>
						<v-btn
							@click="createDialog = false"
							text>
							Cancel
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn
							@click="unholyCrap"
							:disabled="!validEdit"
							color="primary"
							text>
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import permissions from "../../mixins/permissions";
import EditComponent from "../../components/jackpots/EditComponent.vue";
import CardHeading from "../../components/shared/CardHeading.vue";
import { rules as defaultRules } from "../../plugins/rules.ts";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";
import { isMobile } from "../../constants/helpers";
import _ from "lodash";

export default {
	components: {
		CardHeading,
		EditComponent,
		ConfirmDialog2,
	},
	mixins: [permissions],
	data() {
		return {
			validEdit: false,
			loading: true,
			rules: { ...defaultRules },
			noSameName: (value) => {
				return (
					(!this.list
						.map((el) => el.name.toLowerCase())
						.includes(value.toLowerCase()) &&
						!this.perms
							.map((el) => el.name.toLowerCase())
							.includes(value.toLowerCase())) ||
					"Group already exists."
				);
			},
			changedPerms: {},
			textField: "",
			newStudios: [],
			createDialog: false,
			selectedItem: "",
			modifyGroup: false,
			permGroupHeaders: [{ text: "Name", value: "name", align: "center" }],
		};
	},
	computed: {
		isMobile,
		...mapGetters(["casinoList"]),
		...mapGetters("meta2", {
			studios: "gameStudios",
		}),
		...mapGetters("permissions", {
			perms: "list",
			list: "studioGroups",
			detail: "detail",
		}),
		disableSubmit() {
			return (
				this.loading ||
				_.isEqual(
					this.$refs?.editComponent?._selected.concat().sort((a, b) => a - b),
					this?.groupStudios.map((el) => el.name)
				)
			);
		},
		groupStudios() {
			return (
				this.studios?.filter((studio) =>
					this.detail?.studios?.includes(studio.name)
				) ?? []
			);
		},
	},
	methods: {
		...mapActions(["getCasinoList"]),
		...mapActions("meta2", {
			loadStudios: "loadStudios",
		}),
		...mapActions("permissions", {
			loadPerms: "loadList",
			loadList: "loadStudioList",
			loadDetail: "loadDetail",
			create: "create",
			edit: "edit",
			delete: "delete",
		}),
		async unholyCrap() {
			this.$refs.createComponent.submit();
			this.createDialog = false;
			this.newStudios = [];
		},
		async deleteItem() {
			await this.delete(this.selectedItem);
			await this.loadList();
			await this.loadPerms();
			if (this.list.length <= 0) return;
			const preselect = this.$route.query.selected;
			let preselectIndex = this.list.findIndex((el) => el.name === preselect);
			if (preselectIndex === -1) preselectIndex = 0;
			this.selectedItem = this.list[preselectIndex].name;
			await this.loadDetail(this.selectedItem);
		},
		async rowClickHandle(e) {
			if (!e) {
				return;
			}
			this.loading = true;
			this.selectedItem = e;
			await this.loadList();
			await this.loadPerms();
			await this.loadDetail(e);
			this.loading = false;
		},
		async createGroup(values) {
			await this.create({
				data: {
					studios: values,
					name: this.textField.toLowerCase(),
					app: "logan",
				},
			});
			this.onEditStudiosSubmit(this.textField);
		},
		async editGroup(values) {
			await this.edit({
				data: {
					sections: this.detail.sections,
					studios: values,
					casinos: this.detail.casinos,
					name: this.selectedItem.toLowerCase(),
					app: "logan",
				},
			});
			this.onEditStudiosSubmit(this.selectedItem);
		},
		async onEditStudiosSubmit(name) {
			this.loading = true;
			await this.loadList();
			await this.loadPerms();
			this.selectedItem = name;
			await this.loadDetail(this.selectedItem);
			this.loading = false;
			this.textField = "";
		},
	},
	async created() {
		this.loading = true;
		await this.loadList();
		await this.loadPerms();
		await this.getCasinoList();
		await this.loadStudios();
		if (this.list.length <= 0) return;
		const preselect = this.$route.query.selected;
		let preselectIndex = this.list.findIndex((el) => el.name === preselect);
		if (preselectIndex === -1) preselectIndex = 0;
		this.selectedItem = await this.list[preselectIndex].name;
		await this.loadDetail(this.selectedItem);
		this.loading = false;
	},
};
</script>

<style scoped>
::v-deep tr.v-data-table__selected {
	background-color: var(--v-primary-base) !important;
}

.stickyButtons {
	background-color: var(--v-defaultbg-base);
	position: sticky;
	z-index: 1;
	bottom: 16px;
}
</style>
